<template>
	<svg
		aria-hidden="true"
		focusable="false"
		data-prefix="far"
		data-icon="chevron-down"
		role="img"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 448 512"
		:class="[
			'fill-current h-4 w-4 ml-2 transition-transform duration-300',
			{ 'rotate-180': isOpen },
		]"
	>
		<path
			fill="currentColor"
			d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
		></path>
	</svg>
</template>

<script>
export default {
	props: {
		isOpen: {
			type: Boolean,
			required: true,
		},
	},
}
</script>
